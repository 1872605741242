import React from 'react';
import { Fade, Zoom } from 'react-awesome-reveal'; // Removed Slide import
import BuildWebsitemoodle from "../assets/images/lms.svg";
import thmemoodle from "../assets/images/m.svg";
import plugin from "../assets/images/plugin.svg";
import Web from "../assets/images/Web.png";
import Design from "../assets/images/Design.png";
import Mobile from "../assets/images/Mobile.png";

const serviceData = [
  {
    title: "LMS Development",
    imageUrl: BuildWebsitemoodle,
    link: "#",
    delay: 1000,
  },
  {
    title: "Theme Development",
    imageUrl: thmemoodle,
    link: "#",
    delay: 1200,
  },
  {
    title: "Plugin Development",
    imageUrl: plugin,
    link: "#",
    delay: 1200,
  },
  {
    title: "Web Development",
    imageUrl: Web,
    link: "#",
    delay: 1200,
  },
  {
    title: "UI/UX Design",
    imageUrl: Design,
    link: "#",
    delay: 1200,
  },
  {
    title: "Mobile Development",
    imageUrl: Mobile,
    link: "#",
    delay: 1200,
  },
];

export default function Service() {
  return (
    <div className="bg-gray-50">
      <div className="container mx-auto pt-20 pb-28">
        <Fade direction="right" triggerOnce>
          <h1 className="text-5xl text-theme-blue text-center font-bold">Our Service</h1>
        </Fade>
        <Fade direction="left" triggerOnce>
          <p className="font-light text-lg text-gray-400 text-center mb-12">
            We are ready to scale up your business with our great service.
          </p>
        </Fade>

        <div className="grid grid-rows-3 px-10 gap-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6 xl:gap-16">
          {serviceData.map((service) => (
            <Zoom key={service.title} delay={service.delay} triggerOnce>
              <div>
                <a href={service.link}>
                  <div className="bg-white group rounded-2xl shadow-2xl border border-light-theme-purple transform transition duration-500 hover:scale-105">
                    <img src={service.imageUrl} alt={`Custom Box ${service.title}`} className="w-full rounded-t-2xl" />
                    <h2 className="text-theme-blue text-center text-xl py-7 rounded-b-2xl">{service.title}</h2>
                  </div>
                </a>
              </div>
            </Zoom>
          ))}
        </div>
      </div>
    </div>
  );
}
