/* eslint-disable import/extensions */

// Services Img Imports
import Web from '../assets/images/Services/Web.png';
import Mobile from '../assets/images/Services/Mobile.png';
import UIUX from '../assets/images/Services/Design.png';

// Portfolio Img Imports
import Recruiting from '../assets/images/Portfolio/Recruiting.png';
import Stream from '../assets/images/Portfolio/Stream.png';
import Freelance from '../assets/images/Portfolio/Freelance.png';
import Aura from '../assets/images/Portfolio/Aura.png';
import Surtido from '../assets/images/Portfolio/Surtido.png';
import ManagementApp from '../assets/images/Portfolio/ManagementApp.png';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// Testimonials
import Sasha from '../assets/images/Testimonials/Sasha.jpg';
import Reiner from '../assets/images/Testimonials/Reiner.jpg';
import Kruger from '../assets/images/Testimonials/Kruger.jpg';

// TeamMembers
import CEO from '../assets/images/TeamMembers/CEO.jpg';
import HRD from '../assets/images/TeamMembers/HRD.jpg';
import Finance from '../assets/images/TeamMembers/Finance.jpg';
import ProjectManager from '../assets/images/TeamMembers/Project-manager.jpg';
import Frontend1 from '../assets/images/TeamMembers/Frontend1.jpg';
import Frontend2 from '../assets/images/TeamMembers/Frontend2.jpg';
import Backend1 from '../assets/images/TeamMembers/Backend1.jpg';
import Backend2 from '../assets/images/TeamMembers/Backend2.jpg';
import Mobile1 from '../assets/images/TeamMembers/Mobile1.jpg';
import Mobile2 from '../assets/images/TeamMembers/Mobile2.jpg';
import UIUX1 from '../assets/images/TeamMembers/UIUX1.jpg';
import UIUX2 from '../assets/images/TeamMembers/UIUX2.jpg';

export const Services = [
  {
    title: 'Web Development',
    imageUrl: Web,
    animation: 'left',
    
  },
  {
    title: 'Mobile Development',
    imageUrl: Mobile,
    animation: 'up',
  },
  {
    title: 'UI/UX Design',
    imageUrl: UIUX,
    animation: 'right',
  },
];

export const Portfolios = [

  {
    id: 'asd1293ulkmnbj6',
    title: 'Courses Management',
    imageUrl: ManagementApp,
    text: 'Course management refers to the process of organizing, administering, and overseeing the delivery of educational content. It involves creating and structuring courses, managing students, tracking progress, and ensuring the effective use of resources. Course management systems (CMS) or learning management systems (LMS) are widely used in schools, universities, and corporate training to streamline the learning experience for both educators and students.',

    type: 'Website',
    
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: '\nKey Features of Course Management:\n- **Course Creation and Customization**: Educators or administrators can create courses with customizable content such as text, videos, quizzes, assignments, and discussions.\n- **Student Enrollment**: Enables students to register for courses, track enrollment status, and access materials.\n- **Course Scheduling**: Allows instructors to set timelines for lectures, assignments, quizzes, and exams.\n- **Resource Management**: Course materials like reading lists, videos, and downloadable resources are made available to students.\n- **Grading and Assessment**: Provides tools for assessing student performance, including assignments, quizzes, grades, and feedback.\n- **Collaboration and Communication**: Features like forums, messaging systems, and group work allow students and instructors to communicate and collaborate.\n- **Progress Tracking**: Instructors and students can track progress through dashboards that display completed tasks, grades, and overall performance.',
  },
  {
    id: 'asd1293uasdads1',
    title: 'Recruiting App',
    imageUrl: Recruiting,
    type: 'Mobile Apps',
    text: 'A Recruiting App helps job seekers find and apply for positions while enabling employers to post jobs, track applications, and screen candidates. It typically includes features like job search, resume management, applicant tracking, and interview scheduling. The app can be monetized through subscription models or pay-per-listing services. Modern technology stacks like React, Node.js, and cloud storage are often used to build scalable and efficient recruiting platforms.',

    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: '#',
  },
  {
    id: 'asd1293uhjkhkjh2',
    title: 'Stream+',
    imageUrl: Stream,
    type: 'Mobile Apps',
    text: 'Could you clarify what you mean by "Stream about"? Are you asking for details or information on streaming (e.g., live streaming platforms), or are you referring to something specific, like a stream of content or data streaming? Let me know so I can assist you more accurately!',

    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15276430-Stream',
  },
  {
    id: 'asd1293uvbvcbbd3',
    title: 'Freelance',
    imageUrl: Freelance,
    text: 'Freelancing involves providing services to clients on a contract or project basis rather than being employed by one company. Freelancers can work from anywhere and usually have the flexibility to set their own hours and rates. Common freelance roles include writing, graphic design, web development, consulting, marketing, and more.',

    type: 'Mobile Apps',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15223131-Freelance-Mobile-App-Concept',
  },
  {
    id: 'asd1293ufgdfgs4',
    title: 'Aura',
    imageUrl: Aura,
    text: 'Aura  music playlist is a curated collection of songs, tracks, or albums, often grouped together based on specific themes, genres, moods, or personal preferences. Playlists allow listeners to enjoy a seamless listening experience without having to manually select each song.',

    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15176338-Aura-Website-Main-Page',
  },
  {
    id: 'asd1293ulskmnb5',
    title: 'Surtido Rico',
    imageUrl: Surtido,
    text: ' E-commerce (Shoping commerce) refers to the buying and selling of goods and services over the internet. It includes a variety of online activities, from shopping on retail websites to services like online banking, auctions, and digital downloads. E-commerce has revolutionized how businesses interact with customers and has made shopping more accessible and convenient.',

    type: 'Website',
    responsibility: [
      'Web Development',
      'UI/UX Design',
    ],
    credit: 'https://dribbble.com/shots/15173118-Surtido-Rico',
  },
  
];

export const Advantages = [
  [{
    title: 'Communicative',
    description: 'We communicate our project ideas and progress to make it clear.',
    imageUrl: Communicative,
  },
  {
    title: 'Management',
    description: 'We manage our project properly to make our project done well.',
    imageUrl: Management,
  }],
  [{
    title: 'Collaborative​',
    description: 'Our team are very collaborative to make our project done well.',
    imageUrl: Collaborative,
  },
  {
    title: 'Favorite',
    description: "We've did so many project and all of our client love it.",
    imageUrl: Favorite,
  }],
];
export const Testimonials = [
  {
    id: 1,
    name: 'Sasha Rose',
    company: 'Owner, Surveyor Corps',
    testimoni: 'Thanks for Racxstudio, you guys are the best! Keep up the great work!',
    imageUrl: Sasha,
  },
  {
    id: 2,
    name: 'Kruger Khan',
    company: 'Director, Shultan Oil',
    testimoni: 'I just wanted to let you know that it’s been great working with Racxstudio.',
    imageUrl: Kruger,
  },
  {
    id: 3,
    name: 'Reiner John',
    company: 'CEO, Marley CO',
    testimoni: 'Racxstudio is so great. Thank you so much for a job well done.',
    imageUrl: Reiner,
  },
];

export const TeamMembers = [
  {
    name: 'Rach David',
    position: 'CEO',
    imageUrl: CEO,
  },
  {
    name: 'Pauline Sydney',
    position: 'HRD',
    imageUrl: HRD,
  },
  {
    name: 'Granger Watterson',
    position: 'Finance',
    imageUrl: Finance,
  },
  {
    name: 'Tom Jimmy',
    position: 'Project Manager',
    imageUrl: ProjectManager,
  },
  {
    name: 'Jim Hendrix',
    position: 'Front-end Developer',
    imageUrl: Frontend1,
  },
  {
    name: 'Calvin Max',
    position: 'Front-end Developer',
    imageUrl: Frontend2,
  },
  {
    name: 'Hawkins Jim',
    position: 'Back-end Developer',
    imageUrl: Backend1,
  },
  {
    name: 'Don Bizaro',
    position: 'Back-end Developer',
    imageUrl: Backend2,
  },
  {
    name: 'Bill Markinson',
    position: 'Mobile Developer',
    imageUrl: Mobile1,
  },
  {
    name: 'Igor Kavarov',
    position: 'Mobile Developer',
    imageUrl: Mobile2,
  },
  {
    name: 'Freddie Curl',
    position: 'UI/UX Designer',
    imageUrl: UIUX2,
  },
  {
    name: 'Monica Lovegood',
    position: 'UI/UX Designer',
    imageUrl: UIUX1,
  },
];
